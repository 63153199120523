.PlayBoard-module_topWrap__2FiIc {
  margin: 8px 0;
  text-align: center;
}
.PlayBoard-module_button__1VSAl {
  margin: 8px;
}
.PlayBoard-module_buttonGroup__2WGyq {
  margin: 8px;
}
.PlayBoard-module_inputWrap__3ywvT {
  display: flex;
  align-items: center;
  justify-content: center;
}
.PlayBoard-module_inputButton__4u6A1 {
  margin-left: 8px;
}
.PlayBoard-module_showSolution__1RCyv {
  margin: 8px 0 16px;
  text-align: center;
}
.PlayBoard-module_bottom__2uFjS {
  margin-top: 16px;
  text-align: center;
}
.PlayBoard-module_drawer-wrap__hOFWm {
  padding: 16px 32px;
}