.TableBox-module_box__2EKmc {
  position: relative;
  width: 45px;
  height: 45px;
  font-size: 20px;
  border: #DDDDEE solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TableBox-module_box__2EKmc:focus {
  background-color: #DDDDEE;
  outline: none;
}
.TableBox-module_innerInput__1UZZb {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 45px;
  border: 0;
  padding: 0;
  text-align: center;
  font-size: 20px;
  outline: none;
  color: #2F5F9B;
}
.TableBox-module_innerInput__1UZZb:focus {
  background-color: #DDDDEE;
}
.TableBox-module_mistake__3USXU .TableBox-module_innerInput__1UZZb {
  color: #f50057;
}
.TableBox-module_mistake__3USXU .TableBox-module_innerInput__1UZZb:focus {
  color: #2F5F9B;
}
.TableBox-module_userInputIndicator__2OCRy {
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-top: 12px solid #2F5F9B;
  position: absolute;
  top: 0;
  right: 0;
}
.TableBox-module_mistake__3USXU .TableBox-module_userInputIndicator__2OCRy {
  border-top: 12px solid #f50057;
}
.TableBox-module_mistake__3USXU .TableBox-module_innerInput__1UZZb:focus ~ .TableBox-module_userInputIndicator__2OCRy {
  border-top: 12px solid #2F5F9B;
}
.TableBox-module_notesIndicator__1iFB_ {
  bottom: 0;
  right: 0;
  position: absolute;
  width: 16px;
  height: 16px;
  display: flex;
  font-size: 16px;
  color: #2F5F9B;
}
.TableBox-module_solution__3u0A7 {
  color: #2F5F9B;
}
@media only screen and (max-width: 500px) {
  .TableBox-module_box__2EKmc {
    width: 40px;
    height: 40px;
  }
  .TableBox-module_innerInput__1UZZb {
    width: 40px;
  }
}