.Nav-module_nav__1qqv5 {
  position: fixed;
  display: flex;
  padding: 8px 16px;
  border-width: 0 0 3px;
  border-style: solid;
  border-image: linear-gradient(to right, #2F5F9B, #8ECDEA) 1;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background: white;
}
.Nav-module_spacer__3ckeC {
  flex-grow: 2;
}
.Nav-module_logoLink__21dCb {
  text-decoration: none;
  display: flex;
}
.Nav-module_logo__21Xq9 {
  background-image: url("/images/logo.png");
  background-size: contain;
  height: 36px;
  width: 36px;
}
.Nav-module_logoText__18HT- {
  text-decoration: none;
  font-size: 26px;
  margin-left: 8px;
  color: #2F5F9B;
}
.Nav-module_vertical-spacer__lX4_A {
  width: 100px;
  height: 53px;
}
.Nav-module_menu-icon-button__2mRUV {
  padding: 6px;
}
.Nav-module_button__1SzQx {
  margin-left: 8px;
}
