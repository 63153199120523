.SudokuTable-module_table__3Ru7h {
  background-color: #ffffff;
  margin: auto;
  width: fit-content;
  position: relative;
}
.SudokuTable-module_row__2Dg_T {
  display: flex;
  width: fit-content;
}
.SudokuTable-module_topBorder__1uuxu {
  position: absolute;
  top: 0;
  left: 0;
  border-bottom: 2px solid #666699;
  width: 100%;
  z-index: 1;
}
.SudokuTable-module_bottomBorder__1GKlV {
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 2px solid #666699;
  width: 100%;
  z-index: 1;
}
.SudokuTable-module_rightBorder__2OCzP {
  position: absolute;
  top: 0;
  right: 0;
  border-right: 2px solid #666699;
  height: 100%;
  z-index: 1;
}
.SudokuTable-module_leftBorder__WTxU2 {
  position: absolute;
  bottom: 0;
  left: 0;
  border-left: 2px solid #666699;
  height: 100%;
  z-index: 1;
}
.SudokuTable-module_firstRowDivider__1U6JK {
  position: absolute;
  top: 33%;
  left: 0;
  border-bottom: 2px solid #666699;
  width: 100%;
  z-index: 1;
}
.SudokuTable-module_secondRowDivider__2VwvQ {
  position: absolute;
  top: 66.6%;
  left: 0;
  border-bottom: 2px solid #666699;
  width: 100%;
  z-index: 1;
}
.SudokuTable-module_firstColumnDivider__3Gjt1 {
  position: absolute;
  top: 0;
  left: 33.1%;
  border-right: 2px solid #666699;
  height: 100%;
  z-index: 1;
}
.SudokuTable-module_firstColumnDivider6x6__1rvO_ {
  position: absolute;
  top: 0;
  left: calc(50% - 1px);
  border-right: 2px solid #666699;
  height: 100%;
  z-index: 1;
}
.SudokuTable-module_secondColumnDivider__2433P {
  position: absolute;
  top: 0;
  left: 66.6%;
  border-right: 2px solid #666699;
  height: 100%;
  z-index: 1;
}
.SudokuTable-module_doneOverlay__15MI1 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
