.Footer-module_wrap__1bJHk {
  color: white;
  padding: 32px 32px 8px;
  background-color: #3f51b5;
}
.Footer-module_innerWrap__2SOSi {
  display: flex;
  align-items: center;
  padding: 24px;
  margin: auto;
  max-width: 800px;
}
.Footer-module_spacer__CJiAI {
  flex-grow: 1;
}
.Footer-module_displayBlock__354He {
  display: block;
}
.Footer-module_rights__RYAWs {
  text-align: center;
}