.Home-module_wrap__3gi7K {
  padding: 24px;
  margin: auto;
  max-width: 800px;
}
.Home-module_paper__24JJ2 {
  background-color: #f5f5f5;
  padding: 8px;
  margin-bottom: 16px;
}
